import { ReactNode } from 'react'
import cn from 'classnames'

import { LangCurrencyDropdown } from 'components/lang-currency/dropdown'
import { Tooltip } from 'components/tooltip'
import Settings from 'components/settings'

import useAuth from 'lib/hooks/useAuth'
import useRouteMatch from 'lib/hooks/useRouteMatch'
import useCustomTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'
import { useWishlistData } from 'lib/context/wishlist-context'
import { useGlobalContext } from 'lib/context/global-context'

import { BOOKING_PAYMENT_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import { DropdownButton } from '../dropdown-button'
import { LayoutButton as Button } from '../button'

import s from './styles.module.scss'

const DesktopMenu = ({
  currencyOptions,
  languageOptions,
  state,
  showLanguageSelector,
  avatarText,
  toggleMenu,
  handleTrackEventCallback,
  handleCurrencySwitch,
  handleLanguageSwitch,
}: {
  currencyOptions: Option[]
  languageOptions: Option[]
  state: { langId: string; langLabel: string; currencyId: string; currencySymbol: string }
  showLanguageSelector: boolean
  variant?: 'default'
  avatarText: string | ReactNode
  toggleMenu: () => void
  handleTrackEventCallback: (event: any) => void
  handleCurrencySwitch: (nextCurrency: string) => void
  handleLanguageSwitch: (langOrLocale: string) => void
}) => {
  const { t } = useCustomTranslation('common')
  const { trackEvent } = useAppData()
  const { yellowAi } = useGlobalContext()
  const { isLoggedIn, authModal } = useAuth()
  const isBookingsPaymentPage = Boolean(useRouteMatch(BOOKING_PAYMENT_ROUTE))
  const hideAuthEntryPoints = !isLoggedIn && isBookingsPaymentPage
  const wishlist = useWishlistData()

  const handleSignupClick = () => {
    trackEvent.current?.({
      attributeId: `${EVENTS.HEADER}_signup`,
      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
    })
    authModal.open({ screen: 'signup' })
    toggleMenu()
  }

  return (
    <>
      <DropdownButton
        iconName="currency-converter"
        size="small"
        labelResponsive
        dropdown={
          <LangCurrencyDropdown
            activeTab="currency"
            currencyOptions={currencyOptions}
            selectedCurrencyId={state.currencyId}
            onCurrencySwitch={handleCurrencySwitch}
          />
        }
        className={s.headerNavItem}
        dropdownContainerClassName={s.menu}
        dropDownClassName={s.currencyContent}
      >
        {state.currencyId}
      </DropdownButton>

      {showLanguageSelector && (
        <DropdownButton
          iconName="globe"
          labelResponsive
          className={s.headerNavItem}
          dropdownContainerClassName={s.menu}
          dropDownClassName={s.currencyContent}
          dropdown={
            <LangCurrencyDropdown
              activeTab="language"
              selectedLangId={state.langId}
              languageOptions={languageOptions}
              onLanguageSwitch={handleLanguageSwitch}
            />
          }
        >
          {showLanguageSelector && state.langLabel}
        </DropdownButton>
      )}

      <Button
        labelResponsive
        iconName="question"
        onClick={() => {
          yellowAi.showAndToggle()

          trackEvent.current?.({
            attributeId: EVENTS.HEADER,
            attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
          })
        }}
      >
        {t('t.support', { ns: 'common' })}
      </Button>

      {!hideAuthEntryPoints ? (
        isLoggedIn ? (
          <Tooltip
            active={!!wishlist.showTooltip}
            text={t('msg.easilyAccessYourWishlistsHere')}
            position="bottom-end"
            variant="white"
            behaviour="popover-overlay"
            onClose={() => {
              wishlist.setShowTooltipValue(false)
            }}
          >
            <DropdownButton
              // make sure the component re-renders when login status changes
              key={hideAuthEntryPoints ? 'account' : 'account-with-signup'}
              variant="avatar"
              className={cn(s.headerNavItem, s.accountDropdown)}
              dropdown={<Settings trackEvent={handleTrackEventCallback} />}
              dropdownContainerClassName={cn(s.accountMenu, s.wide)}
            >
              {avatarText}
            </DropdownButton>
          </Tooltip>
        ) : (
          <>
            <Button
              onClick={() => {
                trackEvent.current?.({
                  attributeId: `${EVENTS.HEADER}_login`,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
                authModal.open({ screen: 'login' })
                toggleMenu()
              }}
            >
              {t('t.logIn')}
            </Button>
            <DropdownButton
              className={s.signupDropdownButton}
              variant="outline"
              dropdown={
                <div className={s.signupDropdownContent} onClick={handleSignupClick}>
                  <span>
                    {t('msg.signUpToEnjoyDiscountOffYourFirstBookings', {
                      ns: 'common',
                      multiply: 2,
                      discount: 10,
                    })}
                  </span>
                </div>
              }
              dropDownClassName={s.signupDropdown}
              onClick={handleSignupClick}
            >
              {t('t.signUp')}
            </DropdownButton>
          </>
        )
      ) : null}
    </>
  )
}

export { DesktopMenu }
