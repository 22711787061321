import { useCallback, useEffect, useState, useRef } from 'react'
import { debounce as _debounce } from 'lodash-es'
import { DocumentNode } from '@apollo/client'
import posthog from 'posthog-js'

import { logError } from 'lib/utils'

import { DATA_LAYER_EVENT } from 'lib/constants'

import { useSearchLazyQuery } from './apollo/useCustomQuery'

export interface Options {
  initialQuery?: DocumentNode | null
  limitInitialAutoSuggestItems?: number
  searchQuery: DocumentNode
  defaultSearchValue?: string
  limitAutoSuggestItems?: number
  debounce?: number
  filter?: () => Record<string, string | number | boolean | undefined>
  onSearchQueryComplete?: (data: any) => void
}

const useSearch = ({
  initialQuery,
  limitInitialAutoSuggestItems = 3,
  searchQuery,
  defaultSearchValue = '',
  limitAutoSuggestItems = 6,
  debounce = 300,
  filter,
  onSearchQueryComplete,
}: Options) => {
  const filterRef = useRef<() => Record<string, string | number | boolean | undefined>>()
  filterRef.current = filter

  const [query, setQuery] = useState<string>(defaultSearchValue)
  const [isInitialSearchMode, setInitialSearchMode] = useState<boolean>(!!initialQuery)
  // TODO: Remove this below support and use apollo previousData feature when they release new version with this support
  const [prevData, setPrevData] = useState<any>()

  const [doSearch, { loading, error, data }] = useSearchLazyQuery(searchQuery, {
    onCompleted: onSearchQueryComplete,
  })

  const [doInitialLoad, { data: initialData }] = useSearchLazyQuery(
    initialQuery || searchQuery,
    // note: put searchQuery in OR above to just satisfy the useLazyQuery type rules, will not actually use searchQuery when initialQuery is null
    {
      onCompleted: () => {
        setInitialSearchMode(false)
      },
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(
    _debounce(
      (
        q: string,
        _filter?: Record<string, string | number | boolean | undefined>,
        isInitialLoad?: boolean
      ) => {
        const variables = {
          q: q?.trim(),
          pageSize: !isInitialSearchMode ? limitAutoSuggestItems : limitInitialAutoSuggestItems,
          ..._filter,
        }
        if (isInitialLoad) {
          doInitialLoad({ variables })
        } else if (q) {
          doSearch({ variables })
          const payload = {
            [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: q?.trim()
          }
          window?.dataLayer?.push({
            event: DATA_LAYER_EVENT.SEARCH,
            ...payload,
          })
          posthog.capture(DATA_LAYER_EVENT.SEARCH, payload)
        }
      },
      debounce
    ),
    [debounce]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | string) => {
      setQuery(typeof event === 'string' ? event : event.target.value)
    },
    [setQuery]
  )

  const refetch = useCallback(
    (params?: Record<string, string | number | boolean | undefined>) => {
      debouncedQuery(query, params)
    },
    [query, debouncedQuery]
  )

  // initial state api call only
  useEffect(() => {
    if (isInitialSearchMode) {
      debouncedQuery(query, filterRef.current?.(), isInitialSearchMode)
    }
  }, [isInitialSearchMode, debouncedQuery, query])

  // search api call only
  useEffect(() => {
    if (isInitialSearchMode || !query) return
    debouncedQuery(query, filterRef.current?.())
  }, [isInitialSearchMode, query, debouncedQuery])

  useEffect(() => {
    if (defaultSearchValue !== query) {
      setQuery(defaultSearchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchValue, setQuery])

  // log error
  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error])

  // set search result into state to mark it as previousData so when next execution is undefine can use this
  useEffect(() => {
    if (data) {
      setPrevData(data)
    }
  }, [data])

  return {
    query,
    handleChange,
    refetch,
    error,
    searching: loading,
    data: !query ? initialData : data || prevData,
  }
}

export default useSearch
