import { gql } from '@apollo/client'

import { PelagoError } from './fragments'
import { COUNTRIES_QUERY_BODY, PRODUCT_FILTERS_BODY } from './queries'
import { DYNAMIC_COMPONENTS_DATA_QUERY_BODY } from './queries/common'

export * from './queries'
export * from './mutations'
export * from './mapper'

export const GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY = `
  tags {
    tagCode
    tagId
    tagLabel
    tagType
  }
  categories {
    categoryId
    code
    parentId
    value
  }
  currencies {
    countryId
    currencyId
    currencyName
    currencySymbol
  }
  constants
  locales {
    currency
    languageLabel
    languageName
    localeLabel
  }
  rewardsBanner
  whitelabel {
    imageUrl
    partnerId
    partnerName
    partnerWebsiteLink
  }
  campaign(campaignSource: $campaignSource, campaignId: $campaignId) @include(if: $hasCampaign) {
    ... on Campaign {
      campaignId
      campaignName
      startDate
      endDate
      isActive
      campaignMetaData
      mediaData
      dateModified
      content
    }
    ${PelagoError}
  }
`
export const GLOBAL_ARGS_QUERY_AND_CAMPAIGN = gql`
  query globalArgsAndCampaign($hasCampaign: Boolean!, $campaignId: String, $campaignSource: String) {
    ${GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY}
  }
`

export const SEARCH_PAGE_QUERY = {
  query: gql`
    query globalArgsCampaignProductFiltersAndCollection($hasCampaign: Boolean!, $campaignId: String, $campaignSource: String, $dynamicComponentsInput: DynamicComponentDetailsInput!) {
      ${GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY}
      ${COUNTRIES_QUERY_BODY}
      ${PRODUCT_FILTERS_BODY}
      ${DYNAMIC_COMPONENTS_DATA_QUERY_BODY}
    }
  `,
  queryName: {
    tag: 'tags',
    categories: 'categories',
    countries: 'countries',
    campaign: 'campaign',
    productFilters: 'productFilters',
    dynamicComponentsData: 'dynamicComponentsData',
  },
}

export const TRACK_CONTEXT_QUERY = gql`
  query trackContext {
    trackContext {
      ...on PelagoOutput {
        output
      }
      ${PelagoError}
    }
  }
`
