import { gql } from '@apollo/client'

export const COUNTRIES_QUERY_BODY = `
  countries {
    countryCode
    countryId
    countryName
    countryUri
  }
`

export const COUNTRIES_QUERY = {
  query: gql`
    query countries {
      ${COUNTRIES_QUERY_BODY}
    }
  `,
  queryName: 'countries',
}
