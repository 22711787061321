import { gql } from '@apollo/client'

import { PelagoError, destination, searchProductCard } from '../fragments'

export const AUTO_SUGGEST_SEACH_QUERY = {
  query: gql`
      query abSearch($q: String!, $country: String, $withDestination: Boolean!, $destinationPageSize: Int, $productPageSize: Int) {
        autoSuggestDestinationSearch(q: $q, pageSize: $destinationPageSize, country: $country) @include(if: $withDestination) {
          ... on Search {
            destinations {
              ${destination}
            }
            count
          }
          ${PelagoError}
        }
        autoSuggestSearch(q: $q, pageSize: $productPageSize) {
          ... on Search {
            products {
              ${searchProductCard}
              countryId
              searchMeta
            }
            count
          }
          ${PelagoError}
        }
      }
    `,
  queryName: { destinations: 'autoSuggestDestinationSearch', products: 'autoSuggestSearch' },
}

export const SEARCH_QUERY = {
  query: gql`
    query abSearch($q: String!, $country: String, $destination: String, $withDestination: Boolean!, $withProduct: Boolean!, $page: Int, $destinationPageSize: Int, $productPageSize: Int) {
      destinationSearch(q: $q, country: $country, page: $page, pageSize: $destinationPageSize) @include(if: $withDestination) {
        ... on Search {
          destinations {
            ${destination}
            description
            thumbnail
          }
          count
        }
        ${PelagoError}
      }
      productSearch(q: $q, destination: $destination, page: $page, pageSize: $productPageSize, country: $country) @include(if: $withProduct) {
        ... on Search {
          products {
            ${searchProductCard}
          }
          count
        }
        ${PelagoError}
      }
    }
  `,
  queryName: { destinations: 'destinationSearch', products: 'productSearch' },
}

export const TRENDING_SEARCH = {
  query: gql`
    query trendingSearch($searchInput: TrendingSearchInput!) {
      trendingSearch(searchInput: $searchInput) {
        ... on TrendingSearchOutput {
          products {
            productId
            productUri
            productName
            thumbnail
            countryId
            destinationId
            destinationName
            countryName
          }
          destinations {
            name
            countryName
            destinationId
            thumbnail
            countryId
            code
          }
          queries {
            query
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'trendingSearch',
}

export const TRENDING_PRODUCTS = {
  query: gql`
    query trendingSearch {
      trendingSearch {
        ... on TrendingSearchList {
          products {
            productId
            productUri
            productName
            thumbnail
            countryId
            destinationId
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'trendingSearch',
}
