import { gql } from '@apollo/client'

import { DYNAMIC_COMPONENTS_DATA_QUERY_BODY } from './common'

export * from './destination'
export * from './product'
export * from './wishlist'
export * from './settings'
export * from './profile'
export * from './blog'
export * from './countries'

export const DYNAMIC_COMPONENTS_DATA_QUERY = {
  query: gql`
    query dynamicComponentsData($dynamicComponentsInput: DynamicComponentDetailsInput!) {
      ${DYNAMIC_COMPONENTS_DATA_QUERY_BODY}
    }
  `,
  queryName: 'dynamicComponentsData',
}

export const CHAT_BOOKING_DETAILS = {
  query: gql`
    query chatBookingDetails($token: String!) {
      chatBookingDetails(token: $token) {
        ... on ChatBookingDetails {
          activityDate
          bookingId
          productName
          userInfo {
            firstName
            lastName
            email
          }
        }
        ... on PelagoError {
          errorHeader
          errorMessage
          code
          errorDetails
        }
      }
    }
  `,
  queryName: 'chatBookingDetails',
}
